import Link from "next/link";
import React from "react";
import dadoLogo from "images/logo_d.png";
import css from "./LoginLayout.module.scss";

interface Props {
  children: React.ReactNode;
  title?: React.ReactNode;
  titleStyle?: React.CSSProperties;
  subtitle?: React.ReactNode;
}

export default function LoginLayout(props: Props) {
  return (
    <div className={css.auth__container}>
      <div className={css.auth__container_flex}>
        <div className={css.auth__form_container}>
          <Link href="/" passHref>
            <a className={css.linq_logo}>
              <img className={css.linq_logo__img} alt="DADO Logo" src={dadoLogo.src} />
            </a>
          </Link>
          <div className={css.auth__form_wrapper}>
            {props.title && (
              <h2 className={css.auth__title} style={props.titleStyle}>
                {props.title}
              </h2>
            )}
            {props.subtitle && (
              <>
                <p className={css.auth__subtitle}>{props.subtitle}</p>
                <br />
              </>
            )}
            {props.children}
          </div>
          {/* <div className="auth__form-wrapper">{this.renderContent()}</div> */}
        </div>
        <div className={css.auth__form_img} />
      </div>
    </div>
  );
}
