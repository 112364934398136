import LoginLayout from "components/auth/LoginLayout";
import { Formik } from "formik";
import { Form, FormItem, Input, SubmitButton } from "formik-antd";
import Link from "next/link";
import React, { useState } from "react";
import css from "./styles.module.scss";
import * as Yup from "yup";
import { signIn } from "helpers/authenticate";
import { useRouter } from "next/router";
import CreateProfile from "components/auth/CreateProfile";
import { USER_CURRENT_QUERY_GQL } from "gql/userCurrentQuery.gql";
import { UserCurrentQuery } from "__generated__/UserCurrentQuery";
import { useLazyQuery } from "@apollo/client";

const AFTER_LOGIN_REDIRECT = "/companies";

const VALIDATION_SCHEMA = Yup.object({
  email: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
});

export default function Login() {
  const router = useRouter();
  const [error, setError] = useState<Optional<string>>();
  const [submitButtonLoading, setSubmitButtonLoading] = useState<boolean>(false);

  // Note: we call loadUserData() after a successful login to update the Apollo cache.
  // This in-turn causes <Gatekeeper> to re-render.
  const [loadUserData] = useLazyQuery<UserCurrentQuery>(USER_CURRENT_QUERY_GQL);

  // `any` because Cognito's typings are bad :-(
  const [cognitoUserForResetPW, setCognitoUserForResetPW] = useState<Optional<any>>();

  if (cognitoUserForResetPW) {
    // the signing-in user needs to complete their profile
    return <CreateProfile user={cognitoUserForResetPW} />;
  }

  return (
    <LoginLayout
      title="The most useful tool in your toolbox."
      titleStyle={{ marginTop: "80px" }}
      subtitle="Welcome! Log in to your account."
    >
      <Formik
        onSubmit={async (values) => {
          const { email, password } = values;
          let response: AnyObject = {}; // because amplify doesn't have typings :-(
          try {
            response = await signIn({ username: email, password });
          } catch (e) {
            if (e.code === "UserNotFoundException" || e.code === "NotAuthorizedException") {
              setError("Incorrect Username or Password");
              return;
            }
          }

          if (response?.challengeName === "NEW_PASSWORD_REQUIRED") {
            setCognitoUserForResetPW(response);
          } else {
            setSubmitButtonLoading(true);
            // Apollo will re-render <Gatekeeper> after we call loadUserData() and populate the cache:
            loadUserData();
            // if the user is on /auth/login, we need to give the user an extra push and redirect them to our app:
            if (router.asPath === "/auth/login") {
              router.push(AFTER_LOGIN_REDIRECT);
            }
          }
        }}
        initialValues={{ email: "", password: "" }}
        validationSchema={VALIDATION_SCHEMA}
      >
        {(formik) => {
          return (
            <Form>
              <FormItem name="email">
                <Input data-testid="email" name="email" placeholder="Email" />
              </FormItem>
              <FormItem name="password">
                <Input.Password data-testid="password" name="password" placeholder="Password" />
              </FormItem>
              <div className={css.forgotPassword}>
                <Link href="/auth/forgotPassword">
                  <a>Forgot password?</a>
                </Link>
              </div>
              <p className={css.error}>{error}</p>
              <SubmitButton
                disabled={!formik.isValid}
                data-testid="login"
                style={{ width: "100%" }}
                // passing loading={undefined} overrode Formik's default behavior
                // we need to use the spread operator so that we only override Formik when we want loading to be true
                {...(submitButtonLoading && { loading: true })}
              >
                Log In
              </SubmitButton>
            </Form>
          );
        }}
      </Formik>
      <br />
    </LoginLayout>
  );
}
